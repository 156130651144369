import { extendTheme } from '@chakra-ui/react';

export const customColors = {
  cilicium: '#EBE9D6',
  sinCity: '#FF0162',
  black: '#1E1D1D',
};

const customTextStyles = {
  h1: {
    fontSize: '13px',
    fontFamily: 'Disket Mono',
    fontWeight: '900',
    color: customColors.cilicium,
  },
  h2: {
    fontSize: '85px',
    fontFamily: 'Permanent Marker',
    fontWeight: '400',
    color: customColors.sinCity,
  },
  p: {
    fontSize: '11px',
    fontFamily: 'Disket Mono',
    fontWeight: 'regular',
    color: customColors.cilicium,
  },
  li: {
    fontSize: '10px',
    fontFamily: 'Disket Mono',
    fontWeight: 'regular',
    color: customColors.cilicium,
  },
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: customColors.black,
        backgroundImage: 'url("/medias/FiltreGrain.png")',
        backgroundRepeat: 'repeat',
        color: customColors.cilicium,
        fontFamily: 'Disket Mono',
      },
      '--select-bg': customColors.black,
      ...customTextStyles,
    },
  },
  colors: {
    ...customColors,
  },
  components: {
    Button: {
      variants: {
        custom: {
          border: `2px solid ${customColors.cilicium}`,
          background: customColors.black,
          fontFamily: 'Disket Mono',
          borderRadius: '0',
          textTransform: 'uppercase',
          color: customColors.cilicium,
          fontSize: '11px',
          minWidth: '123px',
          justifyContent: 'flex-start',
          _hover: {
            background: customColors.cilicium,
            color: customColors.black,
          },
        },
        customSelected: {
          border: `2px solid ${customColors.cilicium}`,
          background: customColors.cilicium,
          color: customColors.black,
          fontFamily: 'Disket Mono',
          borderRadius: '0',
          fontSize: '11px',
          textTransform: 'uppercase',
          justifyContent: 'flex-start',
          minWidth: '123px',
          _hover: {
            background: customColors.cilicium,
            color: customColors.black,
          },
        },
      },
    },
  },
});
export default theme;
