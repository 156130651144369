import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState<string>('');
  useEffect(() => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    setCurrentDateTime(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`);
  }, []);
  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      setCurrentDateTime(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`);
    };

    // Mettre à jour la date et l'heure toutes les secondes
    const intervalId = setInterval(updateDateTime, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);
  return (
    <Box>
      <Flex onClick={() => navigate('/')} cursor="pointer">
        <Box fontSize="46px" fontFamily="Fit">
          PORTFOLIO {new Date().getFullYear()}
        </Box>
        <Flex direction="column" ml={2}>
          <Box className="glitch">
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="8px">
                UI CREATIVE
              </Box>
            </div>
          </Box>
          <Box className="glitch">
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
            <div className="line">
              <Box as="h2" fontSize="21px" mt="-11px">
                DESIGNER
              </Box>
            </div>
          </Box>
        </Flex>
      </Flex>
      <Flex direction="column" mt={-2}>
        <Box as="p">JuNkP1X3l://19.22.A10.14</Box>
        <Box as="p">#WIlliams</Box>
        <Box as="p">{currentDateTime}</Box>
        <Box as="p">$ UI DESIGNER</Box>
      </Flex>
    </Box>
  );
};

export default Header;
