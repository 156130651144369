import React from 'react';
import { Box, Flex, Grid, GridItem, Image, useBreakpointValue } from '@chakra-ui/react';

type Theme = 'eg' | 'ams' | 'invoice' | 'ams2';

const contentThemes: { [key in Theme]: { title: string; date: string; support: string; description: string } } = {
  eg: {
    title: 'ENTREGEEKS',
    date: '2023 - 2024',
    support: 'WEB',
    description:
      "EntreGeeks est une communauté en ligne dédiée aux passionnés de culture geek et de gaming. J'ai réalisé la refonte complète du site internet, incluant le branding et le design system, en créant un design moderne et intuitif. Le site propose une plateforme dynamique pour organiser des événements et des discussions, avec un serveur Discord actif pour des interactions en temps réel.",
  },
  ams: {
    title: 'PICKup-services AMS',
    date: '2023 - 2024',
    support: 'MOBILE',
    description:
      "Pickup-Services est une entreprise spécialisée dans la gestion des points relais en France et en Europe. Ils m'ont confié l’application mobile internationale de gestion de colis. J'ai créé de nouvelles pages et de nouveaux composants pour le design system, tout en respectant la direction artistique imposée.",
  },
  invoice: {
    title: 'PICKup-services Invoice NG',
    date: '2022 - 2023',
    support: 'application Web interne',
    description:
      "Pickup-Services est une entreprise spécialisée dans la gestion des points relais en france et en Europe. J'ai conçu le design system, ainsi que le illustration de l’application de facturation interne.",
  },
  ams2: {
    title: 'Musée de l’absinthe',
    date: '2022 - 2022',
    support: 'WEB',
    description:
      "Le Musée de l’Absinthe, situé à Auvers-sur-Oise, fait découvrir l’histoire de la « Fée verte ». Pour l’inauguration d’une nouvelle exposition temporaire, j'ai réalisé la refonte complète du site internet ainsi que le rebranding, en créant un design moderne et intuitif",
  },
};

const UiUxInvoice: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [selectedTheme, setSelectedTheme] = React.useState<Theme>('eg');
  const [selectedMedia, setSelectedMedia] = React.useState<number>(1);

  const onSelectedTheme = (theme: Theme) => {
    setSelectedTheme(theme);
    setSelectedMedia(1);
  };

  return (
    <Grid templateColumns={isMobile ? 'auto' : '1fr 3.5fr'} gap={6} mt="60px">
      <GridItem>
        <Box as="p">
          Je prends une approche personnelle dans mes projets d'UI, en intégrant mon expérience dans le support informatique et l'utilisation de logiciels avec ma passion pour l'art et la créativité.
        </Box>
        <Image src="/medias/ux/blabla.png" alt="balbla" mt="-88px" />
        <Flex direction="column" mt={10} justifyContent="center" alignItems="center">
          {[
            { theme: 'ams2', src: '/medias/ux/1.png', alt: 'ams 2', maxW: '246px' },
            { theme: 'invoice', src: '/medias/ux/2.png', alt: 'invoice', maxW: '258px' },
            { theme: 'ams', src: '/medias/ux/3.png', alt: 'ams 1', maxW: '258px' },
            { theme: 'eg', src: '/medias/ux/4.png', alt: 'EG', maxW: '258px' },
          ].map((item, index) => (
            <Box
              key={item.theme}
              mt={index === 0 ? 4 : '-83px'}
              ml={2}
              onClick={() => onSelectedTheme(item.theme as Theme)}
              _hover={{ transform: 'translateY(-10px)', zIndex: 0 }}
              transition="transform 0.3s"
              position="relative"
              zIndex={0}
              cursor="pointer"
            >
              <Image src={item.src} alt={item.alt} maxW={item.maxW} />
            </Box>
          ))}
        </Flex>
      </GridItem>
      <GridItem mx={20}>
        <Box mt={'-120px'}>
          <Flex justifyContent="center">
            <Box as="p" textTransform="uppercase">
              {contentThemes[selectedTheme].title}
            </Box>
          </Flex>
          <Flex justifyContent="center" position="relative">
            <Image src={`/medias/ux/${selectedTheme}/${selectedMedia}.jpg`} alt={selectedTheme} />
            <Box position="absolute" bottom="0" right="0" mb={2}>
              <Flex>
                <Box onClick={() => setSelectedMedia(1)} cursor="pointer" mr={2}>
                  <Image src={`/medias/ux/${selectedTheme}/min/1.jpg`} alt={selectedTheme} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />
                </Box>
                <Box onClick={() => setSelectedMedia(2)} cursor="pointer" mr={2}>
                  <Image src={`/medias/ux/${selectedTheme}/min/2.jpg`} alt={selectedTheme} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Box mx={isMobile ? '0' : '80px'} textTransform="uppercase" mt={2}>
            <Grid templateColumns="1fr 10fr" gap={2}>
              <GridItem as="p">[Date]</GridItem>
              <GridItem as="p">{contentThemes[selectedTheme].date}</GridItem>
              <GridItem as="p">[Support]</GridItem>
              <GridItem as="p">{contentThemes[selectedTheme].support}</GridItem>
              <GridItem as="p">[Description]</GridItem>
              <GridItem as="p">{contentThemes[selectedTheme].description}</GridItem>
            </Grid>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default UiUxInvoice;
