import React from 'react';
import { Box, Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { customColors } from '../theme';

const Footer: React.FC = () => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getButtonVariant = (path: string) => {
    return location.pathname === path ? 'customSelected' : 'custom';
  };

  return (
    <Box py="16px">
      <Flex direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : 'flex-start'}>
        <Box as={Link} to="projet-ui" mt={isMobile ? 2 : 0}>
          <Button variant={getButtonVariant('/projet-ui')}>PROJET UI</Button>
        </Box>
        <Box as={Link} to="projet-perso" ml={isMobile ? 0 : 4} mt={isMobile ? 2 : 0}>
          <Button variant={getButtonVariant('/projet-perso')}>Projet perso</Button>
        </Box>
        <Box as={Link} to="humain" ml={isMobile ? 0 : 4} mt={isMobile ? 2 : 0}>
          <Button variant={getButtonVariant('/humain')}>L'humain</Button>
        </Box>
      </Flex>
      <Flex mt={4} direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : 'flex-start'}>
        <Box as="p" fontSize="13px">
          © Williams chatony {new Date().getFullYear()}
        </Box>
        <Box as="a" href="mailto:Williamschatony@hotmail.com" ml={isMobile ? '0' : '60px'} textDecoration="underline" fontSize="13px">
          Williamschatony@hotmail.com
        </Box>
        <Box mt={isMobile ? 0 : '-4px'}>
          <Box
            as="a"
            fontSize="13px"
            fontWeight="bold"
            px={1}
            href="https://www.linkedin.com/in/williams-chatony-429538107"
            maxW="auto"
            target="_blank"
            ml={isMobile ? '0' : '60px'}
            bg={customColors.cilicium}
            color={customColors.black}
          >
            LINKEDIN
          </Box>
        </Box>
        <Box mt={isMobile ? 0 : '-4px'}>
          <Box
            as="a"
            fontSize="13px"
            fontWeight="bold"
            px={1}
            href="https://www.pinterest.co.uk/pixel_junk/"
            target="_blank"
            ml={isMobile ? '0' : '10px'}
            bg={customColors.cilicium}
            color={customColors.black}
          >
            PINTEREST
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
