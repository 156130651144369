import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Home from './pages/Home';
import UiUxInvoice from './pages/UiUxInvoice';
import ProjetPerso from './pages/ProjetPerso';
import Humain from './pages/Humain';
import Layout from './layout/Layout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout withHeader />,
    children: [
      {
        path: '/projet-ui',
        element: <UiUxInvoice />,
      },
      {
        path: '/projet-perso',
        element: <ProjetPerso />,
      },
      {
        path: '/humain',
        element: <Humain />,
      },
    ],
  },
  { path: '*', element: <Navigate to={'/'} /> },
]);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default Router;
