import React from 'react';
import { Flex } from '@chakra-ui/react';
import ImageGrid from '../components/ImageGrid';
const ProjetPerso: React.FC = () => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} h="100%">
      <ImageGrid />
    </Flex>
  );
};

export default ProjetPerso;
