import React from 'react';
import { Box, Grid, useBreakpointValue } from '@chakra-ui/react';
import Footer from './Footer';
import Header from './Header';
import { Outlet } from 'react-router-dom';

interface LayoutProps {
  withHeader?: boolean;
}

const Layout = ({ withHeader = false }: LayoutProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Grid templateRows={withHeader ? 'auto 1fr auto' : '1fr auto'} minHeight="100vh" px={isMobile ? '5px' : '34px'}>
      {withHeader && (
        <Box as="header">
          <Header />
        </Box>
      )}
      <Box as="main" flex="1" mt={4}>
        <Outlet />
      </Box>
      <Box as="footer">
        <Footer />
      </Box>
    </Grid>
  );
};

export default Layout;
