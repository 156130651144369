import React from 'react';
import { Box, Divider, Grid, Image, ListItem, UnorderedList, useBreakpointValue } from '@chakra-ui/react';
import { customColors } from '../theme';
const Humain: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box mx={isMobile ? 0 : 10}>
      {!isMobile && <Image position="absolute" bottom="0" right="0" src={`/medias/humain/bot.png`} alt={'bot'} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />}
      <Grid templateColumns={isMobile ? 'auto' : '2fr 3fr 4fr'} gap={4} mt={isMobile ? '5px' : '-20px'} w="100%" ml={10}>
        <Box h="100%" p={4}>
          <Box>
            <Image src="/medias/humain/me.jpg" alt="me" />
            <Box as="h1" fontSize="21px" mt={4}>
              Williams CHATONY
            </Box>
            <Box as="h1" fontSize="21px" mt="-7px">
              UI CREATIVE designer
            </Box>

            <Divider my={3} />
            <Box as="p">williamschatony@hotmail.com</Box>
            <Box as="p">France</Box>
          </Box>
        </Box>
        <Box h="100%" p={4}>
          <Box>
            <Box border="1px" p={2} borderColor={customColors.cilicium}>
              SKILLS
            </Box>
            <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
              <Box>
                <Box as="p" fontWeight="700">
                  Conception Visuelle
                </Box>
                <Box as="p">Création de maquettes, wireframes et prototypes.</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Logiciels de Design
                </Box>
                <Box as="p">Maîtrise de Figma, Photoshop, Illustrator et InDesign.</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Responsive Design
                </Box>
                <Box as="p">Conception d'interfaces adaptatives</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Travail en Équipe
                </Box>
                <Box as="p">Connaissance de la méthode agile</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Créativité et Innovation
                </Box>
                <Box as="p">Proposition de solutions créatives et innovantes.</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Connaissance IT
                </Box>
                <Box as="p">Expérience avec Midjourney, ChatGPT, Firefly et Runway. Capacité à apprendre rapidement et à m'adapter à de nouvelles applications.</Box>
              </Box>
            </Box>
          </Box>

          <Box mt={4}>
            <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
              formations
            </Box>
            <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Licence professionnelle Chef de projets digital
                </Box>
                <Box as="p">Digital campus, Paris XI</Box>
                <Box as="p">2024-2025</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  BTS Designer print & web
                </Box>
                <Box as="p">NextFormation, Vincennes</Box>
                <Box as="p">2022</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Prépa communication visuelle
                </Box>
                <Box as="p">Créapole-ESDI, Paris I</Box>
                <Box as="p">2003-2004</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  Prépa stylisme Modélisme
                </Box>
                <Box as="p">Atelier Letelier, Paris XV</Box>
                <Box as="p">2002-2003</Box>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={2}>
                  CAP/BAC PRO Photo
                </Box>
                <Box as="p">Lycée Quinault, Paris XV</Box>
                <Box as="p">1999-2001</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box h="100%" p={4}>
          <Box textTransform="uppercase">
            <Box as="p" fontSize="13px" fontWeight="700" mt={4}>
              Expériences Professionnelles
            </Box>
            <Box>
              <Box as="p" fontWeight="700" mt={4}>
                UI Designer
              </Box>
              <Box as="p">Pickup-Services</Box>
              <Box as="p">2022-2024</Box>
              <UnorderedList my={2}>
                <ListItem>Création de maquettes et prototypes pour l'application mobile des points relais en Europe.</ListItem>
                <ListItem>Conception de maquettes pour l'application web interne de facturation.</ListItem>
                <ListItem>Animation d'ateliers de brainstorming créatif avec les équipes de développement.</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Box as="p" fontWeight="700" mt={2}>
                Stagiaire marketing
              </Box>
              <Box as="p">Pickup-Services</Box>
              <Box as="p">2022-2022</Box>
              <UnorderedList my={2}>
                <ListItem>Réalisation d’illustrations.</ListItem>
                <ListItem>Retouche numérique de photos.</ListItem>
                <ListItem>Création de bannières web.</ListItem>
                <ListItem>Correction, création et mise en page de divers documents avec InDesign et Illustrator.</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Box as="p" fontWeight="700" mt={2}>
                Ingénieur Exploitation
              </Box>
              <Box as="p">Pickup-Services</Box>
              <Box as="p">2018-2022</Box>
              <UnorderedList my={2}>
                <ListItem>Gestion de l'infrastructure IT et des opérations quotidiennes.</ListItem>
                <ListItem>Supervision et assurance de la continuité des services.</ListItem>
                <ListItem>Mise en place de procédures.</ListItem>
                <ListItem>Mise en production des applications.</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Box as="p" fontWeight="700" mt={2}>
                Technicien Systèmes & Réseaux
              </Box>
              <Box as="p">Pickup-Services</Box>
              <Box as="p">2013-2018</Box>
              <UnorderedList my={2}>
                <ListItem>Maintenance et gestion des réseaux informatiques.</ListItem>
                <ListItem>Administration de SharePoint et gestion de l’Active Directory.</ListItem>
                <ListItem>Installation et configuration des équipements systèmes et réseaux.</ListItem>
                <ListItem>Support technique aux utilisateurs et résolution de problèmes.</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Box as="p" fontWeight="700" mt={2}>
                Technicien Systèmes & Réseaux
              </Box>
              <Box as="p">Matis</Box>
              <Box as="p">2010-2013</Box>
              <UnorderedList my={2}>
                <ListItem>Supervision et assurance de la continuité des services.</ListItem>
                <ListItem>Mise en place de procédures.</ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default Humain;
